<template>
  <div class="h-screen-dynamic w-screen flex items-center justify-center bg-beige-20">
    <div class="flex flex-col text-center">
      <h1 class="text-5xl font-medium">Wenatex Video Service</h1>
      <p>SAB: {{ isSharedArrayBuffer }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
const isSharedArrayBuffer = computed(() => typeof SharedArrayBuffer === 'function')
onMounted(() => {
  console.log('SharedArrayBuffer enabled: ', isSharedArrayBuffer.value)
})
</script>